import { redirect } from "@sveltejs/kit"


export function reroute({ url }) {
  if (url.pathname == "/signup") {
    return "/login"
  }

  if (url.pathname == "/privacy-policy.html") {
    return "/about/privacy-policy"
  }

  if (url.pathname == "/terms-of-service.html") {
    return "/about/terms-of-use"
  }

  if (url.pathname.startsWith("/waitlist")) {
    return "/"
  }

  if (url.pathname.startsWith('/stacks/users')) {
    return url.pathname.replace('/stacks/users', '/u')
  }

  if (url.pathname.startsWith('/products/nutrients')) {
    return url.pathname.replace('/products/nutrients', '/products/category')
  }
}