import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://255f6c77f3d449d7b4b75f0fcdd6a2b9@o4505506269757440.ingest.sentry.io/4505506271395840',
  tracesSampleRate: 1.0,
  tunnel: '/sentry/envelope',
  environment: import.meta.env.MODE,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  
  // If you don't want to use Session Replay, just remove the line below:
  integrations: [Sentry.replayIntegration({useCompression: false})],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
